import React from 'react'

import Layout from '../components/Layout/Layout'
import {Seo} from '../components/Seo/Seo'
import {Links} from '../components/Links/Links'

const IndexPage = () => (
  <Layout>
    <Seo title="zawias.pro"/>
    <div style={{textAlign: 'center'}}>
      <h1>zawias.pro</h1>
      <small>Software developer</small>
    </div>
    <Links/>
  </Layout>
)

export default IndexPage
